import React, { useState, useEffect, useContext, useRef } from 'react'
import { HeaderContext } from '../App'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Checkbox from './Checkbox'
import ErrorMessage from '~/components/ErrorMessage'
import Button from '~/components/Button'
import Paypal from '~/components/Paypal'
import Banner from '~/components/Banner'
import Select from '~/components/Select'
import List, { SimpleListItem } from '~/components/List'
import recaptcha from '~/helpers/loadRecaptcha'
import { formatDurationForDisplay, dateRange, timeRange, fullDate } from '~/helpers/dateAndTime'
import http, { wrapButtonToggle } from '~/http'

import useShowLoader from './Loader'
import StudentCard from './StudentCard'

// --Components
function CartPage (props) {
  const { cart, students } = props
  const [globalError, setGlobalError] = useState(null)
  const [paypalButtonDisabled, setPaypalButtonDisabled] = useState(false)
  const [paypalButtonLoaded, setPaypalButtonLoaded] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [captureId, setCaptureId] = useState(null)
  const [mailPermission, setMailPermission] = useState(true)
  const [educationLevelOptions, setEducationLevelOptions] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])
  const showLoader = useShowLoader()

  const mailPermissionRef = useRef(mailPermission)

  const { setHeaderInfo } = useContext(HeaderContext)

  const getPersonOptionData = async () => {
    const response = await http(setGlobalError, http => http.get('/api/SelfService/Students/GetStudentOptionData'))
    const educationLevels = response.data.educationLevels.map(x => {
      return { label: x.name, value: x.id }
    })
    const languages = response.data.languages.map(x => {
      return { label: x.name, value: x.id }
    })

    setLanguageOptions(languages)
    setEducationLevelOptions(educationLevels)
  }

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'My Cart' })
    getPersonOptionData()
  }, [])

  useEffect(() => {
    mailPermissionRef.current = mailPermission
  }, [mailPermission])

  if (captureId != null) {
    props.history.push(`/receipt/${captureId}`)
    window.scrollTo(0, 0)
    props.dispatch({
      type: 'RESET_STATE',
      data: {}
    })
  }

  const showLocationsWarning = cart.classesByType.SQ.length > 0 && Object.keys(cart.sqCampuses).length > 1

  const sectionPrerequisites = cart.classesByType.WEDOE
    .filter(reservation => reservation.section.prerequisite != null)
    .reduce((acc, res) => {
      const { section, course } = res
      const title = course.title.trim()
      if (acc[title] == null) {
        acc[title] = { title, prerequisite: section.prerequisite }
      }
      return acc
    }, {})

  const sectionCeEligibility = cart.classesByType.WEDOE
    .filter(reservation => reservation.section.isCeEligible)
    .reduce((acc, res) => {
      const { course } = res
      const title = course.title.trim()
      if (acc[title] == null) {
        acc[title] = { title }
      }
      return acc
    }, {})

  const readyToPay = cart.classes.filter(x => !cart.reservations.find(reservation => reservation.section.id === x.section.id)).length === 0

  return (
    <div className='page cart'>
      <Banner message='Cart' />

      {overlay && (
        <>
          <div className='page-header'>
            <div className='contents row'>
              Processing
            </div>
          </div>

          <div className='page-body'>
            <div className='contents'>
              <ErrorMessage notice message='Please wait while we process your order...' />
            </div>
          </div>
        </>
      )}

      <div className='page-body extended' style={{ display: overlay ? 'none' : undefined }}>
        {globalError && <ErrorMessage message={globalError} />}
        {showLocationsWarning && <ErrorMessage warning message='Warning: Your cart contains classes at multiple locations' />}

        <div className='empty-cart row'>
          {cart.classes.length === 0 &&
            <>
              <div>Your cart is empty, let's start down a new path to learning.</div>
              <Button
                label='Classes'
                icon='school'
                unelevated
                onClick={() => props.history.push('/ll-course-categories')}
              />
            </>}
        </div>

        {cart.classes.length > 0 &&
          <div>
            <h3>Add Your Student Information</h3>
            {students.length <= 0 && <p>You must add student information to continue.</p>}
            {students.map((student, index) => {
              return (
                <StudentCard
                  key={`student-${student.publicId}-${student.firstName}-${student.lastName}`}
                  student={student}
                  removeStudent={() => removeStudent(props, setGlobalError, student, index)}
                  languageOptions={languageOptions}
                  educationLevelOptions={educationLevelOptions}
                />
              )
            })}

            <Button
              label='Add Student'
              icon='person_add'
              unelevated
              onClick={() => gotoAddStudent(props)}
            />
          </div>}

        {cart.classes.length > 0 && students.length > 0 &&
          <>
            <div className='class-card-list'>
              <h3>Review Your Classes</h3>
              {cart.classes.map((c, index) => {
                const assignedStudents = students
                  .filter(student => cart.reservations.some(res => res.section.id === c.section.id && res.studentPublicId === student.publicId))
                const possibleStudentsToAssign = students
                  .filter(student => !assignedStudents.includes(student))
                  .map(student => { return { label: `${student.firstName} ${student.lastName}`, value: student.publicId } })

                return (
                  <div className='class-card card mdc-card' key={index}>
                    <div>
                      <h3>
                        <a href={`/#/ll-courses/${c.course.id}?section=${c.section.id}`}>
                          {c.type === 'SQ' ? c.course.courseName : c.course.title}
                        </a>
                      </h3>
                      <sub>{c.section.location} - {dateRange(c.section.startDate, c.section.endDate)}</sub>
                      <br />
                      <sub>{c.section.meetingPattern} {c.section.startTime != null && timeRange(c.section.startTime, c.section.endTime)}</sub>
                    </div>
                    <div>
                      <br />
                      {possibleStudentsToAssign.length === 0 &&
                        <p>
                      All students have been assigned to this class already.
                        </p>}
                      <Select
                        className='width-100-percent student-select'
                        name='Assign a student'
                        value=''
                        disabled={possibleStudentsToAssign.length === 0}
                        options={students
                          .filter(student => !cart.reservations.some(res => res.section.id === c.section.id && res.studentPublicId === student.publicId))
                          .map(student => { return { label: `${student.firstName} ${student.lastName}`, value: student.publicId } })}
                        setter={async studentPublicId => {
                          setPaypalButtonDisabled(true)
                          showLoader(true)
                          const student = students.find((x) => x.publicId === studentPublicId)
                          await addToCart(props, setGlobalError, student, c.section, c.course, c.type)
                          showLoader(false)
                          setPaypalButtonDisabled(false)
                        }}
                      />
                      {assignedStudents.length > 0 &&
                        <>
                          <h4>Assigned Students</h4>
                          <List>
                            {cart.reservations.filter(x => x.section.id === c.section.id).map((reservation, i) => {
                              const student = students.find(x => x.publicId === reservation.studentPublicId)
                              return (
                                <SimpleListItem
                                  key={i}
                                  text={`${student.firstName} ${student.lastName}`}
                                  graphic='person'
                                  meta={
                                    <Button
                                      label='Remove'
                                      icon='person_remove'
                                      onClick={
                                        wrapButtonToggle(
                                          setPaypalButtonDisabled,
                                          async () => {
                                            showLoader(true)
                                            await removeFromCart(props, setGlobalError, reservation, student)
                                            showLoader(false)
                                          })
                                      }
                                    />
                                  }
                                />
                              )
                            })}
                          </List>
                        </>}
                    </div>

                  </div>
                )
              })}

            </div>

            {readyToPay &&
              <>
                <div className='total'>{`Total: $${cart.total.toFixed(2)}`}</div>
                <div className='row mail-permission'>
                  <Checkbox
                    nativeControlId='mail-permission'
                    checked={mailPermission}
                    onChange={(evt) => {
                      setMailPermission(evt.target.checked)
                    }}
                    label={'I\'d like to stay connected with Francis Tuttle via email'}
                  />
                </div>
                <div className='discounts'>
                Discounts may be available for people in the following categories: Military/Veteran, FT Board, FT Foundation Board, Employee, Employee Family, Retired Employee, Senior Citizen, Industry Advisor, and Sending School Partner.
                If you fit into one of these, please contact our enrollment offices at 405-717-4900 to process your enrollment.
                </div>
                {
                  Object.keys(sectionPrerequisites).length > 0 && (
                    <div className='prerequisites'>
                      The Classes below contain pre-requisites, some of which are mandated by governing agencies.
                      Please make sure you meet these requirements.
                      If you do enroll, there is a chance you could be removed from the class for not meeting governed mandatory requirements.
                      In this case, your money will be refunded through our normal refund process, which can take up to 10 business days.
                      If you have further questions regarding pre-requisites for this class please call 405-717-4900
                      {
                        Object.keys(sectionPrerequisites)
                          .map((key, idx) => {
                            const { title, prerequisite } = sectionPrerequisites[key]
                            return <div key={idx}>{title} ({prerequisite})</div>
                          })
                      }
                    </div>
                  )
                }
                {
                  Object.keys(sectionCeEligibility).length > 0 && (
                    <div className='continuing-ed'>
                      The Classes below are eligible for continuing education credit.
                      A license is not required to take them.
                      However, failure to provide your information could result in a delay in, or not receiving credit.
                      {
                        Object.keys(sectionCeEligibility)
                          .map((key, idx) => {
                            const { title } = sectionCeEligibility[key]
                            return <div key={idx}>{title}</div>
                          })
                      }
                    </div>
                  )
                }
                <div className='legal-disclosures'>
                  <p>
                  Students must be 16 years old or older in order to enroll in the majority of the classes. There are exceptions, however, for some classes, such as ACT Prep. To check if a class has an exception to the age requirement, please contact our enrollment offices at 405-717-4900.
                  </p>
                  <p>
                  Refund policy: "Payment is due at the time of enrollment. If you let us know you will be unable to attend, more than three business days before the class starts, we will be happy to provide a refund for the cost of tuition, books, and tests; however, any supplies costs are non-refundable.
                  </p>
                  <p>
                  Please note, if you cancel an enrollment within three business days of the first day of the class, no refund will be provided. We will be happy, though, to assist you in transferring to another class. This must occur at the time of cancellation."
                  </p>
                </div>
                <div className='terms-acknowledge'>
                  By clicking any of the payment options below, I agree to the <Link to='/terms'>Terms of Use</Link>.
                </div>
              </>}
            <div className='payment-placeholder'>
              {!paypalButtonLoaded && globalError == null && readyToPay && <span id='paypal-loading'>Loading PayPal, please wait...</span>}

              {!paypalButtonDisabled && readyToPay && (
                <Paypal
                  globalError={globalError}
                  setGlobalError={setGlobalError}
                  setPaypalButtonDisabled={setPaypalButtonDisabled}
                  setPaypalButtonLoaded={setPaypalButtonLoaded}
                  mailPermissionRef={mailPermissionRef}
                  setOverlay={setOverlay}
                  setCaptureId={setCaptureId}
                />
              )}
            </div>
          </>}
      </div>

    </div>
  )
}

// --functions
async function addToCart (props, setGlobalError, student, section, course, courseType) {
  let token = null
  try {
    token = await recaptcha('addToCart')
  } catch {
    // silently fail on recaptcha error
    return
  }

  const payload = {
    recaptchaToken: token,
    cartPublicId: props.cart.publicId,
    studentPublicId: student.publicId,
    sectionId: section.id
  }

  try {
    const endpoint = courseType === 'SQ'
      ? '/api/SelfService/Cart/AddEnrollment'
      : '/api/self-service/cart/wed/oe/add'
    const response = await http(setGlobalError, http => http.post(endpoint, payload))

    const startTime = section.startTime != null
      ? `${formatDurationForDisplay(section.startTime)}-${formatDurationForDisplay(section.endTime)}`
      : 'Online'
    const ageGroup = courseType === 'SQ'
      ? section.ageGroup
      : 'Adult'

    props.dispatch({
      type: 'ADD_RESERVATION',
      data: {
        cart: response.data.cart,
        reservations: response.data.reservations,
        newReservationData: {
          courseId: course.id,
          courseName: courseType === 'SQ' ? course.courseName : course.title,
          section: {
            id: section.id,
            ageGroup,
            availableCapacity: section.availableCapacity,
            campus: courseType === 'SQ' ? section.campus : section.location,
            courseSectionNumber: section.courseSectionNumber,
            price: section.price,
            notEnrollableReason: section.notEnrollableReason,
            startDate: `${fullDate(section.startDate)}-${fullDate(section.endDate)}`,
            startTime,
            prerequisite: section.prerequisite,
            isCeEligible: section.isCeEligible
          },
          studentName: `${student.firstName} ${student.lastName}`,
          studentPublicId: student.publicId,
          type: courseType
        }
      }
    })
  } catch (err) {
    if (err.response != null && err.response.status === 400) {
      setGlobalError(err.response.data.message)
    } else if (err.response != null && err.response.status === 409) {
      setGlobalError('This student already has a reservation in this class.')
    } else if (err.response != null && err.response.status === 422) {
      setGlobalError(err.response.data.message)
    } else if (err.response != null && err.response.status === 424) {
      setGlobalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll into.')
    }
    window.scrollTo(0, 0)
  }
}

async function removeFromCart (props, setGlobalError, reservation, student) {
  const payload = {
    cartPublicId: props.cart.publicId,
    reservationPublicId: reservation.publicId,
    studentPublicId: student.publicId
  }

  const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/RemoveEnrollment', payload))
  props.dispatch({
    type: 'REMOVE_RESERVATION',
    data: {
      cart: response.data.cart,
      reservations: response.data.reservations
    }
  })
}

function gotoAddStudent (props) {
  props.history.push('/addstudent?cart=true')
}

function removeStudent (props, setGlobalError, student, index) {
  if (props.cart.students[student.publicId]) {
    setGlobalError('This student cannot be deleted because your cart contains enrollments for them')
    window.scrollTo(0, 0)
    return
  }
  props.dispatch({
    type: 'REMOVE_STUDENT',
    data: index
  })
  setGlobalError(null)
}

// --mappings
export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(CartPage)
